export const loadFacebookSDK = (d, s, id) => {
  const fjs = d.getElementsByTagName(s)[0]
  const source = 'https://connect.facebook.net/en_US/sdk.js'

  if (d.getElementById(id)) return
  // eslint-disable-next-line
  let js = d.createElement(s)

  js.id = id
  js.src = source
  fjs.parentNode.insertBefore(js, fjs)
}

export const initFacebook = () => {
  const APP_ID = '334508135563967'

  window.fbAsyncInit = function () {
    window.FB.init({
      appId: APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v14.0',
    })
  }
}
