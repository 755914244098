export const appleNonce = Math.random().toString(16).substr(2, 8)

export const handleAppleSingInInit = () => {
  document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    if (event.detail.user !== undefined) {
      apple.firstName = event.detail.user.name.firstName
      apple.lastName = event.detail.user.name.lastName
    }
  })

  //todo pass to .env file
  /*const clientIdByLocation = window.location.host.includes('77d-website.')
    ? 'com.77diamondslocal.login'
    : 'com.77diamonds.com.login'*/

  window.AppleID.auth.init({
    clientId: 'com.77diamonds.com.login',
    scope: 'name email',
    redirectURI: `${window.location.origin}/redirect`,
    state: Math.random().toString(16).substr(8, 14),
    nonce: appleNonce,
    usePopup: true,
  })
}
