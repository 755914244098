export const googleSignIsReady = () => {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.accounts) {
      resolve()
    } else {
      const checkGoogleInterval = setInterval(() => {
        if (window.google && window.google.accounts) {
          clearInterval(checkGoogleInterval)
          resolve()
        }
      }, 1500)
    }

    setTimeout(() => {
      reject(new Error('Google Sign-In API not ready.'))
    }, 5000)
  })
}

export const handleRenderGoogleButton = (googleBtn: NodeList, topbar: HTMLDivElement | undefined) => {
  if (!googleBtn || !topbar) {
    console.error('Not possible to calc the container for google button')
    return
  }

  const defaultRules = { theme: 'outline', size: 'large', longtitle: true }

  googleBtn.forEach((button, index) => {
    window.google.accounts.id.renderButton(
      button,
      index === 0
        ? {
            ...defaultRules,
            shape: 'circle',
            type: 'icon',
          }
        : {
            ...defaultRules,
            logo_alignment: 'center',
            shape: 'rect',
            type: 'standard',
            width: topbar.offsetWidth,
          },
    )
  })
}
